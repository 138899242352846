export const postPageWidgetDesign = {
  title: 'Post Page Design',
  tabs: [
    { label: 'Post Title', groups: { roles: ['title'] } },
    { label: 'Post Author', groups: { roles: ['text1'] } },
    {
      label: 'Post Counters',
      groups: {
        roles: [
          'likesDefaultNumber',
          'likesFilledNumber',
          'viewsNumber',
          'commentsNumber',
        ],
      },
    },
    {
      label: 'Body Text',
      groups: {
        compTypes: ['wixui.RichContentViewer'],
      },
    },
    {
      label: 'Secondary Text',
      groups: {
        roles: ['text5', 'text2', 'text3'],
      },
    },
    { label: 'Background', groups: { roles: ['postPageRoot'] } },
  ],
};
