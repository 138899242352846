import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (_, editorSDK) => {
  editorSDK.addEventListener('globalDesignPresetChanged', async (event) => {
    const { componentRef } = event.detail;
    const [parentRefComponent] = await editorSDK.components.getAncestors('', {
      componentRef,
    });
    const hasOverrides = await editorSDK.components.refComponents.hasOverrides(
      '',
      {
        componentRef: parentRefComponent,
      },
    );

    if (hasOverrides) {
      await editorSDK.components.refComponents.removeAllOverrides('', {
        componentRef: parentRefComponent,
      });
    }
  });
};
