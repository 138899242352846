import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

const paidContentId = 'box1';
const freeContentId = 'box2';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Post Content',
    nickname: 'Post ContentWidget',
  });

  widgetBuilder.configureWidgetViewState(freeContentId, (viewStateBuilder) => {
    viewStateBuilder.title('Content without paywall');
  });

  widgetBuilder.configureWidgetViewState(paidContentId, (viewStateBuilder) => {
    viewStateBuilder.title('Content with paywall');
  });
};
